@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700");
.cursor {
  cursor: pointer;
}
.navexpand {
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;

}
.validation-error {
  font-size: 0.8em;
  font-style: italic;
  color: #dc3545;
}
.dragging-item {
  list-style: none;
}
.tool-buttons {margin-bottom: 9px;}
.buttons-companies {margin: 6px;}
.tool-buttons button {margin-right: 9px;}
.delete-ingrid-but:hover i {color:#dd4b39;}
.select__value-container {min-width:100px;}
.flags-menu img {margin-right:10px;}

.react-confirm-alert-overlay {z-index:2001 !important;}
.main-headery {z-index:1 !important;}

.pac-container {
    z-index: 9100 !important;
}

.zevgan_modal {
  display: none;
  position: fixed;
  z-index: 10000;
  padding: 10px 62px 0px 62px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color:rgba(0, 0, 0, 0.5);
}

.zevgan_modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 0 0 0 0;
  width: 80%;
  max-width: 1200px;
}

.appraisals_modal {
  display: none;
  position: fixed;
  z-index: 10000;
  padding: 10px 62px 0px 62px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color:rgba(0, 0, 0, 0.5);
}

.appraisals_modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 0 0 0 0;
  width: 80%;
  max-width: 1200px;
}

.rag-green {
background-color:#d9fce3 !important;
}

.row-color-green {
	background-color:#e5f6df !important;
}

.row-color-red {
	background-color:#ffe6e6 !important;
}

.row-color-orange {
	background-color:#fed5a4 !important;
}

.row-color-white {
	background-color:#ffffff !important;
}

.row-color-yellow {
	background-color:#ffff00 !important;
}

.row-color-grey {
	background-color:grey !important;
}

.row-color-yellow-red-text {
	background-color:#ffff00 !important;
	color: red;
}

.row-color-white-red-text {
	background-color:#ffffff !important;
	color: red;
}

.row-color-white-green-text {
	background-color:#ffffff !important;
	color: green;
}

.row-color-light-blue {
	background-color:#ADD8E6 !important;
}

.row-color-light-blue-center {
	background-color:#ADD8E6 !important;
	text-align: center;
}

.row-color-white-black-text {
	background-color:#ffffff !important;
	color: black;
}

#loadingsFinalCalculationGrid .ag-root .ag-header {
	display: none;
}

.ag-popup-editor {z-index:12000 !important;}

.ag-popup {z-index:19000 !important;}
.ag-popup-child {z-index:19003 !important;}

#ui-datepicker-div {z-index:12000 !important;}
.ag-menu {z-index:12000 !important;}
.ag-row-level-1 {
  background-color: #fffff2 !important;
}

.inlinecheckbox {
    position: relative;
    display: inline;
    margin-top: 10px;
    margin-bottom: 10px;
}

.inlinecheckbox input{ position:relative; top:2px;}
.rightdate .rdtPicker {right:0}
.rdrDateDisplayWrapper {display:none}
.daterange_flt {position:absolute; z-index:101;
/*
right:0;
*/
}

#backgroundOverlay{
    background-color:transparent;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:none;
}


.FastSelectInput {
	outline: 0px solid white;
	background-image: url(https://cdn-icons-png.flaticon.com/512/151/151851.png);
	background-size: 9px 9px;
	background-origin: border-box;
	background-repeat: no-repeat;
	display: block;
	background-position: calc(100% - 10px) center;
    padding-right: 30px;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
	box-shadow: inset 0 0 rgb(0, 0, 0);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	height: calc(2.25rem + 2px);
    padding-left: 10px;
}

.FastSelectInput:focus {
	outline: 0px solid white;
	border-color: #80bdff;
	box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5)
}

.FastSelectInput:focus-visible {
	border-color: #80bdff;
}

.FastSelectUnchecked {
	background-color: white;
	background-image: url(https://www.svgrepo.com/show/309415/checkbox-unchecked.svg);
	background-size: 22px 22px;
	background-repeat: no-repeat;
	padding-left: 22px;
	display: block;
}

.FastSelectChecked {
	background-color: white;
	background-image: url(https://www.svgrepo.com/show/309414/checkbox-checked.svg);
	background-size: 22px 22px;
	background-repeat: no-repeat;
	padding-left: 22px;
	display: block;
	font-weight: bolder
}

.FastSelectElement {
	background-color: white;
	display: block;
}

.FastSelectSelected {
	background-color: lightgray;
	color: black;
	font-weight: bolder
}

.FastSelectSelected:hover {
	background-color: #E8E8E8;
	color: black;
	font-weight: bolder;
}

.FastSelectUnselected {
	background-color: white;
}

.FastSelectUnselected:hover {
	background-color: #E8E8E8;
	color: black;
	font-weight: bolder;
}

.FastSelectChecked:hover {
	background-color: lightgray;
	color: black;
	font-weight: bolder;
}

.FastSelectUnchecked:hover {
	background-color: lightgray;
	color: black;
	font-weight: bolder;
}

.ButtonInCell {
	height: 40px;
    width: 100%;
}

.ButtonSquare {
	height: 40px;
    width: 40px;
	background-color: black;
	color: #ffffff;
	border-radius: 6px;
}

.ButtonSquare:hover {
	height: 40px;
    width: 40px;
	background-color: black;
	color: #ffffff;
	border-radius: 6px;
	opacity: 0.6;
}

.CellForButton {
	padding-top: 30px;
}

.TextHeader1 {
	font-weight: bolder;
    font-size: 18px;
}

.TextHeader2 {
	height: 10vh;
}